import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CancelButton, Icons } from "@src/components";
import { styled } from "@mui/material/styles";
const TableContainer = styled("table")({
  border: "1.5px solid #7D7D7D",
  borderCollapse: "collapse",
  "& td, th": {
    padding: "16px",
    border: "1.5px solid #7D7D7D",
    "@media (max-width: 600px)": {
      padding: "4px"
    }
  }
});
const TableHead = styled("tr")({
  backgroundColor: "#434343",
  color: "white",
  textAlign: "center",
  fontSize: {
    xs: "12px",
    md: "16px"
  },
  fontWeight: "600",
  lineHeight: "24px",
  textTransform: "uppercase"
});
const Title = styled(Typography)({
  fontSize: {
    xs: "10px",
    md: "14px"
  },
  fontWeight: "600",
  whiteSpace: {
    xs: "wrap",
    lg: "nowrap"
  },
  display: "flex",
  justifyContent: "center"
});
const ListItem = styled(Typography)({
  fontSize: {
    xs: "10px",
    md: "14px"
  },
  lineHeight: "24px",
  display: "list-item",
  marginLeft: "20px"
});
const Phone = styled("a")({
  fontWeight: 600,
  color: "#000",
  textTransform: "none",
  textDecoration: "none"
});
const PaymentItem = ({
  onClose
}) => <Box position="relative" data-sentry-element="Box" data-sentry-component="PaymentItem" data-sentry-source-file="change.tsx">
    <TableContainer data-sentry-element="TableContainer" data-sentry-source-file="change.tsx">
      <tbody>
        <TableHead data-sentry-element="TableHead" data-sentry-source-file="change.tsx">
          <td></td>
          <td>Online</td>
          <td>Offline</td>
        </TableHead>
        <tr>
          <td rowSpan="2">
            <Title data-sentry-element="Title" data-sentry-source-file="change.tsx">Điều kiện đổi hàng</Title>
          </td>
          <td colSpan="2">
            <ListItem data-sentry-element="ListItem" data-sentry-source-file="change.tsx">
              Sản phẩm còn nguyên tem mác, chưa qua sử dụng và sửa chữa
            </ListItem>
            <ListItem data-sentry-element="ListItem" data-sentry-source-file="change.tsx">Có hóa đơn mua hàng</ListItem>
          </td>
        </tr>
        <tr>
          <td>Thời gian đổi hàng kể từ khi nhận được sản phẩm: 5 ngày</td>
          <td>Thời gian đổi hàng sau khi rời quầy thanh toán: 5 ngày</td>
        </tr>
        <tr>
          <td>
            <Title data-sentry-element="Title" data-sentry-source-file="change.tsx">Cách thức đổi</Title>
          </td>
          <td>
            Liên hệ bộ phận CSKH qua hotline{" "}
            <Phone href="tel:0775.665.912" data-sentry-element="Phone" data-sentry-source-file="change.tsx">0775.665.912</Phone> và nhắn tin qua
            page để được hỗ trợ đổi hàng. Hỗ trợ đổi hàng mua Online tại bất kì
            cửa hàng nào của shop
          </td>
          <td>
            Quý khách hàng có thể đổi sản phẩm ở bất kì cửa hàng nào của shop.
          </td>
        </tr>
        <tr>
          <td>
            <Title data-sentry-element="Title" data-sentry-source-file="change.tsx">Giá trị hàng hoán đổi</Title>
          </td>
          <td colSpan="2">
            Chỉ áp dụng khi sản phẩm muốn đổi có giá lớn hơn hoặc bằng sản phẩm
            đã mua Trường hợp sản phẩm đổi mới thấp hơn sản phẩm cũ, shop không
            hỗ trợ hoàn trả lại tiền chênh lệch.
          </td>
        </tr>
        <tr>
          <td>
            <Title data-sentry-element="Title" data-sentry-source-file="change.tsx">Chính sách đổi</Title>
          </td>
          <td colSpan="2">
            <ListItem data-sentry-element="ListItem" data-sentry-source-file="change.tsx">Mỗi đơn hàng chỉ hỗ trợ đối 1 lần</ListItem>
            <ListItem data-sentry-element="ListItem" data-sentry-source-file="change.tsx">Shop không có chính sách trả hàng</ListItem>
            <ListItem data-sentry-element="ListItem" data-sentry-source-file="change.tsx">
              Chính sách đổi hàng không áp dụng đối với hàng sale
            </ListItem>
          </td>
        </tr>
        <tr>
          <td>
            <Title data-sentry-element="Title" data-sentry-source-file="change.tsx">Phí ship đổi hàng</Title>
          </td>
          <td>
            Miễn phí: hàng bị lỗi từ phía sản xuất, shop giao sai mẫu, sai kích
            thước Khách hàng chịu phí ship 2 chiều: đổi theo yêu cầu của quý
            khách hàng
          </td>
          <td>
            Không mất phí khi ghé bất kì cửa hàng nào của shop để đổi hàng
          </td>
        </tr>
        <tr>
          <td rowSpan="2">
            <Title data-sentry-element="Title" data-sentry-source-file="change.tsx">Lưu ý</Title>
          </td>
          <td colSpan="2">
            Shop chỉ nhận trả hàng trong trường hợp sản phẩm bị lỗi từ shop.
          </td>
        </tr>
        <tr>
          <td>
            Quý khách vui lòng không gửi hàng khi chưa có sự xác nhận của bộ
            phận CSKH của shop. Nếu xảy ra sự cố dẫn đến mất sản phẩm shop hoàn
            toàn không chịu trách nhiệm.
          </td>
          <td></td>
        </tr>
      </tbody>
    </TableContainer>
    <CancelButton type="button" height="40px" label="Thu gọn" endIcon={<Icons name="arrow-up" />} minHeight="40px" minWidth="160px" sx={{
    margin: {
      xs: "24px auto 0",
      md: "48px auto 0"
    }
  }} onClick={onClose} data-sentry-element="CancelButton" data-sentry-source-file="change.tsx" />
  </Box>;
export default PaymentItem;