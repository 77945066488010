"use client";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useRouter } from "next/navigation";
import { useCallback, useEffect, useState } from "react";
import { CancelButton, Icons, ProductCard } from "@src/components";
import { CONFIG_COLLECTION } from "@src/constants/category";
import useProduct from "@src/services/use-product";
import EmptyProductSale from "./empty-product";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import HomeSectionContainer from "@src/sections/home/home-section-container";
import { useLayoutContext } from "@src/layouts/provider";
const CategoryTitle = ({
  children,
  disabled,
  active,
  onClick
}) => {
  const activeStyle = {
    color: "black",
    fontWeight: 700,
    backgroundPosition: "100% 100%",
    backgroundSize: "100% 3px !important",
    "& svg": {
      opacity: 1
    }
  };
  return <Button variant="text" disableRipple sx={{
    color: "rgba(0, 0, 0, 0.5)",
    fontSize: {
      xs: 16,
      md: 22
    },
    fontWeight: 400,
    lineHeight: "32px",
    minWidth: {
      xs: "auto",
      md: 150
    },
    border: "none",
    borderRadius: 0,
    width: "fit-content",
    padding: 0,
    textTransform: "none",
    whiteSpace: "nowrap",
    display: "inline-block",
    paddingBottom: "3px",
    backgroundImage: "linear-gradient(#000 0 0)",
    backgroundPosition: "0 100%",
    backgroundSize: "0% 2px",
    backgroundRepeat: "no-repeat",
    transition: "background-size 0.3s,  background-position 0s 0.3s",
    "& svg": {
      opacity: 0.5
    },
    "&:hover": {
      color: "black",
      backgroundPosition: "100% 100%",
      backgroundSize: "100% 2px",
      backgroundColor: "transparent",
      "& svg": {
        opacity: 1
      }
    },
    ...(active && activeStyle)
  }} disabled={disabled} onClick={onClick} data-sentry-element="Button" data-sentry-component="CategoryTitle" data-sentry-source-file="products.tsx">
      {children}
    </Button>;
};
const ProductList = () => {
  const router = useRouter();
  const {
    config,
    refetch
  } = useLayoutContext();
  const {
    Service,
    entries,
    fetching
  } = useProduct();
  const [tabActive, setTabActive] = useState(null);
  const handleChangeTab = tab => () => {
    if (!fetching) {
      setTabActive(tab);
    }
  };
  useEffect(() => {
    if (config) {
      if (config.on_sale) {
        setTabActive(CONFIG_COLLECTION.ON_SALE);
      } else {
        setTabActive(CONFIG_COLLECTION.NEW_IN);
      }
    }
  }, [config]);
  useEffect(() => {
    if (tabActive && tabActive?.key) {
      Service.getList({
        pageSize: 12,
        type: tabActive.key
      });
    }
  }, [tabActive?.key]);
  const handleViewAll = useCallback(() => {
    router.push(tabActive.url);
  }, [tabActive?.key]);
  const renderProductList = () => {
    if (fetching && entries?.length === 0 || !config) {
      return new Array(12).fill(0).map((_, index) => <Grid key={index} item xs={6} lg={3}>
          <Box width="100%">
            <Skeleton variant="rectangular" width="100%" height={330} sx={{
            borderRadius: 1
          }} />
            <Box sx={{
            pt: 0.5
          }}>
              <Skeleton width="100%" />
              <Skeleton width="60%" />
            </Box>
            <Box sx={{
            pt: 0.5
          }}>
              <Skeleton width="100%" />
              <Skeleton width="60%" />
            </Box>
          </Box>
        </Grid>);
    }
    if (fetching === false && entries?.length === 0) {
      return <EmptyProductSale />;
    }
    return entries.map((product, index) => <Grid key={index} item xs={6} lg={3}>
        <ProductCard product={product} />
      </Grid>);
  };
  return <HomeSectionContainer data-sentry-element="HomeSectionContainer" data-sentry-component="ProductList" data-sentry-source-file="products.tsx">
      <Stack mb={2} justifyContent="center" alignItems="center" spacing={{
      xs: 4,
      sm: 8,
      md: 14
    }} data-sentry-element="Stack" data-sentry-source-file="products.tsx">
        {/* Have flash sale */}
        {config?.on_sale && <CategoryTitle disabled={fetching} active={tabActive?.key === CONFIG_COLLECTION.ON_SALE.key} onClick={handleChangeTab(CONFIG_COLLECTION.ON_SALE)}>
            ON SALE
          </CategoryTitle>}
        <CategoryTitle disabled={fetching} active={tabActive?.key === CONFIG_COLLECTION.NEW_IN.key} onClick={handleChangeTab(CONFIG_COLLECTION.NEW_IN)} data-sentry-element="CategoryTitle" data-sentry-source-file="products.tsx">
          NEW ARRIVALS
        </CategoryTitle>
        {/* <CategoryTitle >
          <SiennaSvg />
         </CategoryTitle> */}
        <CategoryTitle disabled={fetching} active={tabActive?.key === CONFIG_COLLECTION.BEST_SELLER.key} onClick={handleChangeTab(CONFIG_COLLECTION.BEST_SELLER)} data-sentry-element="CategoryTitle" data-sentry-source-file="products.tsx">
          BEST SELLERS
        </CategoryTitle>
        {/* No flash sale */}
        {!config?.on_sale && <CategoryTitle disabled={fetching} active={tabActive?.key === CONFIG_COLLECTION.ON_SALE.key} onClick={handleChangeTab(CONFIG_COLLECTION.ON_SALE)}>
            ON SALE
          </CategoryTitle>}
      </Stack>
      <Grid container spacing={{
      xs: 1,
      md: 3
    }} sx={{
      marginLeft: {
        xs: "-8px",
        md: "-24px"
      },
      width: {
        xs: "calc(100% + 8px)",
        md: "calc(100% + 24px)"
      }
    }} data-sentry-element="Grid" data-sentry-source-file="products.tsx">
        {renderProductList()}
      </Grid>
      {entries?.length > 0 && <CancelButton type="button" height="40px" label="Xem tất cả" endIcon={<Icons name="arrow-right" />} minHeight="40px" sx={{
      margin: {
        xs: "24px auto 0",
        md: "48px auto 0"
      },
      minWidth: {
        xs: "220px !important",
        md: "328px !important"
      }
    }} onClick={handleViewAll} />}
    </HomeSectionContainer>;
};
export default ProductList;