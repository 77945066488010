import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CancelButton, Icons } from "@src/components";
const Contents = ["Nội thành: 1-2 ngày (Tp.HCM)", "Ngoại thành và các tỉnh lân cận: 2-3 ngày", "Các tỉnh miền Trung: 3-5 ngày", "Các tỉnh miền Bắc: 3-5 ngày"];
const Delivery = ({
  onClose
}) => <Box position="relative" data-sentry-element="Box" data-sentry-component="Delivery" data-sentry-source-file="delivery.tsx">
    {Contents.map((content, index) => <Typography key={index} sx={{
    display: "list-item",
    marginLeft: "20px"
  }} variant="body1" lineHeight="24px">
        {content}
      </Typography>)}

    <Typography mt={2} color="#D4380D" variant="body1" lineHeight="24px" data-sentry-element="Typography" data-sentry-source-file="delivery.tsx">
      Lưu ý: Có thể phát sinh 1-2 ngày do Lễ Tết, thời tiết hoặc một số lỗi hệ
      thống trong quá trình giao hàng.
    </Typography>

    <CancelButton type="button" height="40px" label="Thu gọn" endIcon={<Icons name="arrow-up" />} minHeight="40px" minWidth="160px" sx={{
    margin: {
      xs: "24px auto 0",
      md: "48px auto 0"
    }
  }} onClick={onClose} data-sentry-element="CancelButton" data-sentry-source-file="delivery.tsx" />
  </Box>;
export default Delivery;