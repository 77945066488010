import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CancelButton, Icons } from "@src/components";
import NextLink from "next/link";
const HeadphoneItem = ({
  onClose
}) => <Box position="relative" data-sentry-element="Box" data-sentry-component="HeadphoneItem" data-sentry-source-file="headphone.tsx">
    <Typography mb={2} fontWeight={700} variant="body1" lineHeight="24px" data-sentry-element="Typography" data-sentry-source-file="headphone.tsx">
      Mọi ý kiến của khách hàng vui lòng liên hệ:
    </Typography>
    <Typography sx={{
    display: "list-item",
    marginLeft: "20px"
  }} variant="body1" lineHeight="24px" data-sentry-element="Typography" data-sentry-source-file="headphone.tsx">
      Hotline:
      <a style={{
      textDecoration: "none",
      paddingLeft: "4px"
    }} href="tel:1900 1234">
        <Typography sx={{
        color: "#000",
        marginLeft: "5px",
        fontWeight: 600
      }} variant="body1" data-sentry-element="Typography" data-sentry-source-file="headphone.tsx">
          0775.665.912
        </Typography>
      </a>
    </Typography>
    <Typography sx={{
    display: "list-item",
    marginLeft: "20px"
  }} variant="body1" lineHeight="24px" data-sentry-element="Typography" data-sentry-source-file="headphone.tsx">
      Địa chỉ: 64 Tân Canh, Phường 1, Quận Tân Bình
    </Typography>
    <Typography sx={{
    display: "list-item",
    marginLeft: "20px"
  }} variant="body1" lineHeight="24px" data-sentry-element="Typography" data-sentry-source-file="headphone.tsx">
      Inbox page:
    </Typography>
    <Box data-sentry-element="Box" data-sentry-source-file="headphone.tsx">
      <Stack mt={1} alignItems="center" data-sentry-element="Stack" data-sentry-source-file="headphone.tsx">
        <Icons sx={{
        paddingRight: 0.5,
        height: 18
      }} name="facebook-address" data-sentry-element="Icons" data-sentry-source-file="headphone.tsx" />
        <Stack sx={{
        flexWrap: "wrap",
        "a ": {
          color: "#389E0D"
        }
      }} alignItems="center" gap={0.5} data-sentry-element="Stack" data-sentry-source-file="headphone.tsx">
          <NextLink href="https://facebook.com/theciu" target="_blank" data-sentry-element="NextLink" data-sentry-source-file="headphone.tsx">
            Facebook/THECIU
          </NextLink>
          <span> hoặc </span>
          <NextLink href="https://facebook.com/theciusaigon" target="_blank" data-sentry-element="NextLink" data-sentry-source-file="headphone.tsx">
            Facebook/THECIUSAIGON
          </NextLink>
        </Stack>
      </Stack>
      <Stack sx={{
      "a ": {
        color: "#389E0D"
      }
    }} mt={1} alignItems="center" data-sentry-element="Stack" data-sentry-source-file="headphone.tsx">
        <Icons sx={{
        paddingRight: 0.5,
        height: 18
      }} name="instagram" data-sentry-element="Icons" data-sentry-source-file="headphone.tsx" />
        <NextLink href="https://instagram.com/theciusaigon" target="_blank" data-sentry-element="NextLink" data-sentry-source-file="headphone.tsx">
          Instagram/theciusaigon/
        </NextLink>
      </Stack>
    </Box>
    <CancelButton type="button" height="40px" label="Thu gọn" endIcon={<Icons name="arrow-up" />} minHeight="40px" minWidth="160px" sx={{
    margin: {
      xs: "24px auto 0",
      md: "48px auto 0"
    }
  }} onClick={onClose} data-sentry-element="CancelButton" data-sentry-source-file="headphone.tsx" />
  </Box>;
export default HeadphoneItem;